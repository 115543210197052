<template>
    <div class="main-container"  dir="ltr">
        <div class="row" dir="rtl">
            <div class="col-xl-6 col-md-6">
                <div class="my-account-content2" dir="rtl" style="background: linear-gradient(0deg,#004d4d 0,#03acac );"> 
                    <div class="header-profile-login">
                        <div class="row justify-content-between">
                            <div class="col-8 col-sm-8 col-lg-8 col-xl-8">
                                <h6 class="text-center" style="color: #ffffff;font-size: 20px;">تسجيل دخول</h6>
                            </div>
                            <div class="col-4 col-sm-4 col-lg-4 col-xl-4" style="padding-right: 0;">
                                <img style="width:130px;float:left;" class="logo" src="../../assets/images/logoSvg.svg" />
                            </div>
                        </div>
                        <form id="loginform" @submit.prevent="onLogin()">
                            <p class="login-username">
                                <label for="user_login" style="margin-top: 20px; margin-bottom: 20px; text-align: center;color: #ffffff;">رقم الهاتف</label>
                                <input type="text" v-model="msisdn" class="input" size="15" autocomplete="off" placeholder="09XXXXXXXX" onfocus="this.placeholder = ''" onblur="this.placeholder = 'رقم الهاتف'">
                            </p>
                            <p class="login-submit">
                                <button class="button button-primary" style="width: 100%; background: linear-gradient(0deg,#077b7b 0,#03acac ); color: rgb(255, 255, 255);">
                                    <span v-if="loading" class="px-1">جاري تسجيل الدخول</span> &nbsp;
                                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                                    <span v-else>تسجيل الدخول</span>
                                </button>
                                <!-- <button type="submit" style="width: 100%;background: #078CC5;" name="wp-submit" id="wp-submit" class="button button-primary" value="إشتـــراك"></button> -->
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { useToast } from "vue-toastification";
export default {
name:'AppLogin',
data() {
    return {
        msisdn: "",
        loading: false,
    };
  },
  mounted() {
    if (this.$cookie.isCookieAvailable("msisdn") && this.$cookie.isCookieAvailable("status")) {
        this.$router.push({ path: "/" })
    }
  },
methods: {
   async onLogin() {
         const toast = useToast();
          if (this.msisdn != "") {
               var phoneno = (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/);
               if (this.msisdn.match(phoneno)) {
                  if (this.msisdn.startsWith("09")) {
                     let phone = this.msisdn.substr(1);
                     this.loading = true
                     await HTTPDSP.post("DSPCheckLogin.php?msisdn=249"+phone).then((res) => {
                            if (res.data.status == 1 && res.data.remming_minutes > 0) {
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة");
                                setTimeout(() => this.$router.push({ path: "/" }), 2500);
                            } else if (res.data.status == 1 && res.data.remming_minutes < 0) {
                                toast.success("تم تسجيل الدخول", { timeout: 2500, });
                                this.$cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('msisdn', res.data.msisdn, { expire: 60 * res.data.remming_minutes, })
                                this.$cookie.setCookie('minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes,})
                                toast.success("تم تسجيل دخولك استمتع بتجربة فريدة");
                            } else if (res.data.status == 0) {
                                toast.warning("عفوا انت لست مشترك في هذة المنصة", { timeout: 2000, });
                                setTimeout(() => { window.open("https://dsplp.sd.zain.com/?p="+res.data.product_code, "_self"); }, 2500);
                              
                            }else {
                                toast.info("عفوا انت لست مشترك في الخدمة ");
                                setTimeout(() => { window.open("https://dsplp.sd.zain.com/?p="+res.data.product_code, "_self"); }, 2500);
                              //   window.open("http://test.zaindsp.com:3033/?p="+res.data.product_code);	
                            }
                        }).catch((error) => {
                           console.log(error);
                        }).finally(() => {
                           this.loading = false;
                        });
                  } else {
                     toast.info("رقم الهاتف ليس زين");
                  }
               } else {
                  toast.info("رقم الهاتف يجب ان يتكون من 10 رقم ويبدا بي 09 ");
               }
            } else {
               toast.info("الرجال ادخال رقم الهاتف");
            }
      }
  },
}
</script>

<style>

</style>